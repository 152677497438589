<template>
  <!-- 全部回复 -->
  <div class="comment_detail">
    <nav-bar title="评论详情" left-arrow @click-left="$router.history.go(-1)" />
    <div class="wrap">
      <div class="list">
        <div class="box">
          <!-- 发布内容 -->
          <div class="top">
            <div class="user">
              <div class="name">
                <img :src="info.avatar == '' ? require('@/assets/img/noface.png'):info.avatar" alt="">
                <span>{{info.user_name}}</span>
              </div>
              <div class="time">{{info.create_time}}</div>
            </div>
            <div class="info">
              <div class="text">{{info.content}}</div>
              <div class="imgs">
                <div v-for="(i,d) in info.imgs" :key="d"><img :src="i" alt=""></div>
              </div>
            </div>
            <div class="comment">
              <img src="@/assets/img/like.png" alt="" @click="zan(info.id,$event)" v-if="info.is_like != 1">
              <img src="@/assets/img/like_select.png" alt="" @click="zan(info.id)" v-else>
              <span>{{info.give_num == 0 ? '':info.give_num}}</span>
              <img src="@/assets/img/comment.png" alt="" @click="comment">
            </div>
            <!-- 评论 -->
            <div class="bottom">
              <div v-for="(j,d) in info.comments_list" :key="d">
                <span class="name">{{j.user_name}}：</span>
                <span class="text">{{j.content}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 评论 -->
      <div class="ipt" v-show="ipt_show"><input type="text" name="" id="" placeholder="输入你想要发布的内容吧~" v-model="text"><span @click="send">发送</span></div>
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      headerName: '全部回复',
      msg: '', // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      id: '',
      info: '', // 初始数据
      text: '',
      ipt_show: false,
    }
  },
  mounted () {
    this.id = this.$route.query.id;
    this.getdata()
  },
  methods: {
    getdata () {
      this.$post('/culture/getdynamicDetail',{did: this.id},(res)=>{
        if (res.data.code == 1) {
          this.info = res.data.data;
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      })
    },
    // 点赞
    zan (id,e) {
      $(e.currentTarget).attr('src',require('@/assets/img/like_select.png'))
      this.$post('/culture/dynamicGive',{did: id},(res)=>{
        if (res.data.code == 1) {
          for (let i = 0; i < this.dongtai.length; i++) {
            if (this.info.id == id) {
              this.info.give_num = res.data.data;
            }
          }
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      })
    },
    // 评论
    comment () {
      this.ipt_show = true;
    },
    // 发送
    send () {
      if (this.text == '') {
        this.msg = '请输入内容';
        this.showAlert = true;
        return;
      }
      var did = this.id;
      var pid = this.info.comments_list.slice(-1)[0].id;
      this.$post('/culture/dynamicComment',{did,pid: 0,content: this.text},(res)=>{
        if (res.data.code == 1) {
          this.msg = '发送成功';
          this.showAlert = true;
          this.ipt_show = false;
          this.text = '';
          this.getdata();
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
          this.ipt_show = false;
          this.text = ''
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.comment_detail {
  width: 100%;
  min-height: 100vh;
  background: #F2ECD4;
  .wrap {
    position: absolute;
    top: 100px;
    width: 100%;
    .list {
      padding: 20px;
      .box {
        width: 100%;
        margin-bottom: 10px;
        .top {
          position: relative;
          padding: 20px;
          border-radius: 20px;
          background: white;
          .user {
            display: flex;
            justify-content: space-between;
            .name {
              display: flex;
              align-items: center;
              img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-right: 10px;
              }
              span {
                font-size: 28px;
                color: #646464;
              }
            }
            .time {
              font-size: 24px;
              color: #646464;
            }
          }
          .info {
            width: 100%;
            .text {
              margin: 20px 0;
              font-size: 28px;
              font-weight: bold;
            }
            .imgs {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              div {
                width: 33.33%;
                height: 200px;
                padding: 4px;
                box-sizing: border-box;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 20px;
                  margin-bottom: 8px;
                }
              }
            }
          }
          .comment {
            width: 140px;
            display: flex;
            justify-content: space-between;
            float: right;
            margin-top: 20px;
            img {
              width: 40px;
              height: 40px;
            }
            span {
              font-size: 28px;
              color: #ff5555;
            }
          }
        }
        // 评论
        .bottom {
          width: 100%;
          background: #eeeeee;
          margin-top: 80px;
          div {
            display: flex;
            padding: 20px;
            span {
              display: inline-block;
            }
            .name {
              font-size: 28px;
              color: #646464;
            }
            .text {
              width: 500px;
              font-size: 24px;
              color: #646464;
              word-break: break-all;
              margin-top: 4px;
            }
          }
        }
        .more {
          display: flex;
          justify-content: space-between;
          width: 88%;
          font-size: 28px;
          background: #999;
          color: white;
          padding: 10px 40px;
          border-radius: 20px;
        }
      }
    }
  }
  // 评论输入框
  .ipt {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 120px;
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    input {
      width: 70%;
      height: 50%;
      border: none;
      outline: none;
      background: #eeeeee;
      border-radius: 40px;
      padding-left: 40px;
      font-size: 30px;
    }
    input::-webkit-input-placeholder{
      color: #A2A2A2;
    }
    span {
      padding: 10px 20px;
      background: #AB0404;
      color: white;
      font-size: 28px;
      border-radius: 40px;
    }
  }
}
</style>